<template>
	<div class="insure_page_class">
		<div class="insure_body">
			<div class="title_top_text PingFangMedium">保障内容</div>
			<div class="guarantee_content_class">
				<div class="table_div">
					<div class="bottom_tab">
						<div class="div_table one_width">
							保障方案
						</div>
						<div class="div_table table_top_class">
							人民普惠保普洱
						</div>
					</div>
					<div class="bottom_tab">
						<div class="div_table one_width">
							年度赔付限额
						</div>
						<div class="div_table right_class">
							200万（医保内100万、医保外100万）
						</div>
					</div>
					<div class="bottom_tab">
						<div class="div_table one_width">
							保险责任
						</div>
						<div class="div_table right_class">
							疾病住院和特殊门诊医疗费用
						</div>
					</div>
					<div class="bottom_tab">
						<div class="div_table one_width">
							起付标准(元)
						</div>
						<div class="div_table right_class">
							投保年度内累计医保内<font class="font_clor">5000</font>元；医保外<font class="font_clor">10000</font>元
						</div>
					</div>
					<div class="bottom_tab">
						<div class="div_table one_width">
							年度报销比例
						</div>
						<div class="div_table right_class">
							<div class="towlin_class">
								本地医保：医保范围内<font class="font_clor">90%</font>，医保范围外<font class="font_clor">60%</font><br/>
								异地就医：医保范围内<font class="font_clor">80%</font>，医保范围外<font class="font_clor">50%</font>
							</div>
						</div>
					</div>
					<div class="bottom_tab">
						<div class="div_table one_width">
							保费
						</div>
						<div class="div_table right_class">
							<font class="font_clor_bold">139元/人/年</font>
						</div>
					</div>
				</div>
			</div>
			<!--<div class="title_top_text PingFangMedium">选择保障方案</div>-->
			<!--<div class="programme_form">-->
				<!--<div class="programme_class" v-for="(item,index) in product_plan" :key="index" :class="insure_data_form.programme_id === item.id?'programme_class_click':''" @click="programmeclick(item)">-->
					<!--<span>{{item.name}}</span>-->
					<!--<img class="img_icon" v-if="insure_data_form.programme_id === item.id" src="https://oss.nuohuipay.cn/ClientProject/mobile/puer/images/1650279429769.png">-->
				<!--</div>-->
			<!--</div>-->
			<div class="title_top_text PingFangMedium">投保信息</div>
			<div class="sumber_input_form">
				<el-form :model="insure_data_form" :rules="insure_data_rules" ref="insure_data_form" label-width="200px" label-position="left" class="demo-ruleForm">
					<el-form-item label="单位名称" prop="policy_holder_name">
						<el-input v-model.trim="insure_data_form.policy_holder_name" placeholder="请输入单位名称" clearable style="width: 60%"></el-input>
					</el-form-item>
					<el-form-item label="所在城区" prop="town">
						<el-select v-model="insure_data_form.town" placeholder="请选择所在城区" clearable style="width: 60%">
							<el-option v-for="(list,index) in province_list" :label="list" :value="list" :key="index"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="统一社会信用代码" prop="policy_holder_card_number" v-if="insure_data_form.insure_type === '企业'">
						<el-input v-model.trim="insure_data_form.policy_holder_card_number" placeholder="请输入组织机构或统一社会信用代码" clearable style="width: 60%"></el-input>
					</el-form-item>
					<el-form-item label="营业执照" prop="business_img" v-if="insure_data_form.insure_type === '企业'">
						<div  class="insure_div_img">
							<el-upload
									class="avatar-uploader"
									:action="url+ '/user/uploadOssFile'"
									:show-file-list="false"
									accept=".jpg, .jpeg, .png"
									:on-success="(file,fileList)=>{return proofsheet_success(file,fileList)}">
								<img v-if="insure_data_form.business_img" :src="insure_data_form.business_img" class="avatar"/>
								<i v-else class="el-icon-plus avatar-uploader-icon"></i>
							</el-upload>
							<i class="el-icon-error postion_icon" @click="del_img_insure" v-if="insure_data_form.business_img"></i>
						</div>

					</el-form-item>
					<el-form-item label="联系人手机号" prop="policy_holder_mobile">
						<el-input v-model.trim="insure_data_form.policy_holder_mobile" placeholder="请输入联系人手机号" clearable style="width: 60%"></el-input>
					</el-form-item>
					<el-form-item label="联系人姓名" prop="contact_name">
						<el-input v-model.trim="insure_data_form.contact_name" placeholder="请输入联系人姓名" clearable style="width: 60%"></el-input>
					</el-form-item>
					<el-form-item label="联系人邮箱" prop="contact_email" v-if="insure_data_form.insure_type === '企业'">
						<el-input v-model.trim="insure_data_form.contact_email" placeholder="请输入联系人邮箱" clearable style="width: 60%"></el-input>
					</el-form-item>
					<el-form-item label="联系人地址" prop="contact_address" v-if="insure_data_form.insure_type === '企业'">
						<el-input v-model.trim="insure_data_form.contact_address" placeholder="请输入联系人地址" clearable style="width: 60%"></el-input>
					</el-form-item>
				</el-form>
			</div>
			<div class="title_top_text PingFangMedium">参保人信息</div>
			<div class="sumber_table_div">
				<div class="button_table">
					<el-button class="el-icon-plus" @click="addtable_data"> 增加参保人</el-button>
					<el-upload action="" :on-change="excel_change" :auto-upload="false" :show-file-list="false" accept=".xls,.xlsx" style="margin: 0 10px">
						<el-button class="el-icon-upload2"> 上传参保清单</el-button>
					</el-upload>
					<el-button class="el-icon-download" @click="downloadFun('ClientProject/BackManage/参保人导入模板.xlsx')"> 下载导入模板</el-button>
					<el-button class="el-icon-document-delete" @click="delall_table"> 批量删除</el-button>
				</div>
				<el-table :data="insureds.slice((currentPage-1)*pagesize,currentPage*pagesize)" ref="insureds" border style="width: 100%" :row-key="getRowKeys" @selection-change="handleSelectionChange" :row-class-name="tableRowClassName" :header-cell-style="getRowClass">
					<el-table-column type="selection" width="40" :reserve-selection="true"></el-table-column>
					<el-table-column type="index" width="60" label="序号" align="center"></el-table-column>
					<el-table-column label="姓名" align="center">
						<template slot-scope="scope">
							<el-input v-model="scope.row.n" placeholder="请输入姓名"></el-input>
						</template>
					</el-table-column>
					<el-table-column prop="id" label="证件类型" align="center">
						<template slot-scope="scope">
							<el-select v-model="scope.row.ct" placeholder="请选择证件类型">
								<el-option label="身份证" value="身份证"></el-option>
								<el-option label="户口簿" value="户口簿"></el-option>
							</el-select>
						</template>
					</el-table-column>
					<el-table-column prop="id" label="证件号码" align="center" width="220">
						<template slot-scope="scope">
							<el-input v-model="scope.row.cn" placeholder="请输入证件号码" v-if="scope.row.ct === '身份证'" @change="getidcard(scope.row)"></el-input>
							<el-input v-model="scope.row.cn" placeholder="请输入证件号码" v-else @change="get_card_all(scope.row)"></el-input>
						</template>
					</el-table-column>
					<el-table-column label="性别" align="center" width="150">
						<template slot-scope="scope">
							<el-select v-model="scope.row.s" placeholder="请选择性别">
								<el-option label="男" value="男"></el-option>
								<el-option label="女" value="女"></el-option>
							</el-select>
						</template>
					</el-table-column>
					<el-table-column label="出生日期" align="center" width="250">
						<template slot-scope="scope">
							<el-date-picker v-model="scope.row.b" type="date" style="width: 180px" placeholder="选择出生日期" format="yyyy年MM月dd日" value-format="yyyy/MM/dd"></el-date-picker>
						</template>
					</el-table-column>
					<el-table-column label="手机号(选填)" align="center" width="180">
						<template slot-scope="scope">
							<el-input v-model="scope.row.m" placeholder="请输入手机号(选填)"></el-input>
						</template>
					</el-table-column>
					<el-table-column label="操作" align="center" width="100" fixed="right">
						<template slot-scope="scope">
							<el-button type="text" @click="del_table(scope.row.id)">删除</el-button>
						</template>
					</el-table-column>
				</el-table>
				<div class="bottom_color_tips">特别提醒：被保人必须为普洱市基本医疗保险参保人（含新农合）</div>
				<div class="pagination_class">
					<el-pagination
							@current-change="handleCurrentChange"
							:current-page.sync="currentPage"
							:page-size="pagesize"
							layout="total, prev, pager, next"
							:total="insureds.length">
					</el-pagination>
				</div>
			</div>
			<div class="file_div_list">
				<el-checkbox v-model="checked_file">我已阅读并确认</el-checkbox>
				<div class="list_onte" v-for="(file,index) in tabs_title" :key="index" @click="looke_file(file.file.path)">
					<span>《{{file.type_mean}}》</span>
				</div>
			</div>
			<div class="sumber_button">
				共计<span class="num_people PingFangMedium">{{insureds.length}}</span>人参保
				<span class="many_text">{{insureds.length * many_num}}</span>
				<span class="company">元</span>
				<el-button type="primary" class="sumber_button_from" @click="submitForm('insure_data_form')">确认投保</el-button>
			</div>
		</div>
		<el-dialog :visible.sync="check_mobile_dialog" width="450px" append-to-body :show-close="false">
			<div class="check_mobile_div">
				<div class="top_title_dialog">
					<div class="title_font PingFangMedium">请验证联系人手机号</div>
					<img class="close_icon" @click="check_mobile_dialog = false" src="https://oss.nuohuipay.cn/ClientProject/mobile/guangfubao/images/1646363357475.png">
				</div>
				<div class="title_tip_class">您的手机号码将用于登录查看订单</div>
				<div class="input_sumber_div">
					<div class="input_list_div">
						<div class="ingput_list_class">
							<img class="icon_tel" src="https://oss.nuohuipay.cn/ClientProject/mobile/guangfubao/images/1646217201564.png">
							<span class="input_class">{{mobileValue}}</span>
						</div>
						<div class="code_input_div">
							<input class="input_class" v-model="mobileCode" type="text" placeholder="请输入验证码">
							<button class="code_button" v-if="sendAuthCode" @click="getAuthCode">获取验证码</button>
							<button class="code_button" v-if="!sendAuthCode">获取验证码 {{auth_time}}S</button>
						</div>
						<div class="erro_tip">{{erro_tip}}</div>
						<el-button type="primary" class="sumber_button_from" @click="verifycode">确定</el-button>
					</div>
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import app from '../../assets/api/apiurl'
    export default {
        name: "insure_page",
	    data(){
            let policy_holder_card_number = (rule, value, callback) => {
                let patrn = /^[0-9A-Z]+$/;
                if (value === '') {
                    callback(new Error('请输入组织机构或统一社会信用代码'));
                }else if(value.length !==18 || !patrn.test(value)){
                    callback(new Error('不是有效的统一社会信用编码！'));
                } else {
                    callback();
                }
            };
            let policy_holder_mobile = (rule, value, callback) => {
                let phone = /^1[3456789]\d{9}$/;
                if (value === '') {
                    callback(new Error('请输入手机号'));
                }else if(!phone.test(value)){
                    callback(new Error('手机号输入有误'));
                } else {
                    callback();
                }
            };
            return{
                tabs_title: [],//挂网文件
                url:app.API_URL,
                insure_data_form:{
                    programme_id:'',//方案
                    insure_type:'企业',//类型
                    policy_holder_name:'',//乡镇村/单位名称
                    town:'',//乡镇村街道
                    policy_holder_card_number:'',//社会代码
                    business_license:'',//营业执照id
                    business_img:'',//营业执照图片
                    policy_holder_mobile:'',//联系人手机号
                    contact_name:'',//联系人姓名
                    contact_email:'',//联系人邮箱
                    contact_address:'',//联系人地址
                    pid:app.PRODUCT_ID,//产品id
                    cid:'',//渠道id
                    smid:'',//业务员id
                },
                insureds:[],//被保人数据
                many_num:0,//金额
                table_row_select:[],
                checked_file:false,
                currentPage:1,//第几页
                pagesize:20,//每页条数
                insure_data_rules:{
                    insure_type: [
                        { required: true, message: '请选择投保类型', trigger: 'change' }
                    ],
                    town: [
                        { required: true, message: '请选择乡镇村街道', trigger: 'change' }
                    ],
                    business_img: [
                        { required: true, message: '请上传营业执照图片', trigger: 'change' }
                    ],
                    policy_holder_name: [
                        { required: true, message: '请输入乡镇村/单位名称', trigger: 'blur' },
                        { min: 2, max: 50, message: '长度在 2 到 50 个字符', trigger: 'blur' }
                    ],
                    contact_name: [
                        { required: true, message: '请输入联系人姓名', trigger: 'blur' },
                        { min: 2, max: 50, message: '长度在 2 到 50 个字符', trigger: 'blur' }
                    ],
                    contact_email: [
                        { required: true, message: '请输入邮箱地址', trigger: 'blur' },
                        { type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur', 'change'] }
                    ],
                    contact_address: [
                        { required: true, message: '请输入联系人地址', trigger: 'blur' }
                    ],
                    policy_holder_card_number: [
                        { required: true, validator:policy_holder_card_number,trigger: 'blur' }
                    ],
                    policy_holder_mobile: [
                        { required: true, validator:policy_holder_mobile,trigger: 'blur' }
                    ],
                },
				province_list: ['思茅区','孟连县','澜沧县','西盟县','江城县','宁洱县','墨江县','景谷县','景东县','镇沅县','其他'],
                check_mobile_dialog:false,//验证手机号
                mobileValue: '',//手机号
                mobileCode: '',//验证码
                sendAuthCode: true,
                auth_time: 0,
                erro_tip: '',//错误提示
                product_plan:[],//保障方案
            }
	    },
	    mounted(){
            this.getorder_file();
            let smid = localStorage.getItem('smid');
            let cid = localStorage.getItem('cid');
            if (smid){
                this.insure_data_form.smid = smid;
            }
            if (cid){
                this.insure_data_form.cid = cid;
            }
            let again = this.$route.query.status;
            if (again === 'again'){//重新投保
                let id =  this.$route.query.id;
                this.getorder_data(id);
                this.order_details_search(id);
            }
            this.get_product_plan();
	    },
	    methods:{
            get_product_plan(){//获取产品方案
                this.$ajax.get(app.API_URL+'/user/get_product_plan',{
                    params:{
                        product_id:app.PRODUCT_ID
                    }
                }).then(res=>{
                    if (res.data.code === 1){
                        let data = res.data.data;
                        let max = Number(data[0].insured_money);
                        for (let i = 1; i < data.length; i++) {
                            max = max < Number(data[i].insured_money) ? Number(data[i].insured_money) : max;
                        }
                        this.many_num = max;
                        data.forEach(el=>{
                            if (Number(el.insured_money) === max){
                                this.insure_data_form.programme_id = el.id;
                            }
                        });
                        this.product_plan = data;
                    }
                })
            },
            // programmeclick(item){//方案选择
            //     this.insure_data_form.programme_id = item.id;
            //     this.many_num = Number(item.insured_money);
            // },
            getorder_data(id){//获取上一条订单数据
                this.$ajax.get(app.API_URL+'/order/info',{
                    params:{
                        id:id,
                        type:'detail'
                    }
                }).then(res=>{
                    if (res.data.code === 1){
	                    let data = res.data.data;
                        let file_path ='';
                        if (data.business_license_file){
                            file_path = app.OSS_URL+ data.business_license_file.path
	                    }
	                    this.insure_data_form = {
							insure_type:data.insure_type_mean,//类型
							policy_holder_name:data.policy_holder_name,//乡镇村/单位名称
							town:data.town,//乡镇村街道
							policy_holder_card_number:data.policy_holder_card_number,//社会代码
							business_license:data.business_license,//营业执照id
							business_img:file_path,//营业执照图片
							policy_holder_mobile:data.policy_holder_mobile,//联系人手机号
							contact_name:data.contact_name,//联系人姓名
							contact_email:data.contact_email,//联系人邮箱
							contact_address:data.contact_address,//联系人地址
							pid:app.PRODUCT_ID,//产品id
							cid:data.channel_id,//渠道id
							smid:data.salesman_id,//业务员id
						}
                    }
                })
            },
            order_details_search(id){//获取上一条订单参保人
                this.$ajax.get(app.API_URL+'/order/query_insured',{
                    params:{
                        order_id:id,
                        page:1,
                        limit:2000,
                    }
                }).then(res=>{
                    if (res.data.code === 1){
                       let data = res.data.data;
                       let arr = [];
                       data.forEach(el=>{
	                       arr.push({
                               id:'id_'+el.customer.id,
                               n:el.customer.name,
                               ct:el.customer.card_type_mean,
                               cn:el.customer.card_number,
                               b:el.customer.birth,
                               s:el.customer.sex_mean,
                               m:el.customer.mobile,
	                       })
                       });
                        this.insureds = arr;
                    }
                })
            },
            getorder_file(){//获取挂网文件
                this.$ajax.get(app.API_URL+'/order/product',{
                    params:{
                        pid:app.PRODUCT_ID
                    }
                }).then(res=>{
                    if (res.data.code === 1){
						this.tabs_title = res.data.data;
                    }
                })
            },
            proofsheet_success(res, file) {//上传营业执照
                this.insure_data_form.business_img = app.OSS_URL + res.data.path;
                this.insure_data_form.business_license = res.data.file_id;
            },
            del_img_insure(){//删除图片
                this.insure_data_form.business_img = '';
                this.insure_data_form.business_license = '';
            },
            submitForm(formName){//提交
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        let table = this.insureds;
                        if (table.length === 0){
                            this.$message.error('参保人信息不能为空');
                            return false;
                        }
                        for (let i =0;i<table.length;i++){
                            if (table[i].n === ''){
                                this.$message.error('第'+(i+1)+'条参保人姓名不能为空');
                                return false;
                            }
                            if (table[i].ct === ''){
                                this.$message.error('第'+(i+1)+'条参保人证件类型不能为空');
                                return false;
                            }
                            if (!table[i].cn){
                                this.$message.error('第'+(i+1)+'条参保人证件号不能为空');
                                return false;
                            }
                            if (table[i].ct === '身份证') {
                                if(!(/(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/.test(table[i].cn))){
                                    this.$message.error('第'+(i+1)+'条参保人身份证号码格式错误');
                                    return false;
                                }
                            }
                            if (table[i].s === ''){
                                this.$message.error('第'+(i+1)+'条参保人性别不能为空');
                                return false;
                            }
                            if (table[i].b === ''){
                                this.$message.error('第'+(i+1)+'条参保人生日不能为空');
                                return false;
                            }
                        }
                        if (!this.checked_file){
                            this.$message.error('请查看我已阅读并确认《参保须知》\n' +
                                '《理赔须知》\n' +
                                '《保险条款》\n' +
                                '《特别约定》\n' +
                                '《隐私协议》\n' +
                                '《保险方案》\n' +
                                '《客户告知书》\n' +
                                '《健康服务手册》');
                            return false;
                        }
                        this.$ajax.post(app.API_URL+'/order/verify_order',{
                            insureds: JSON.stringify(this.insureds),
                            ...this.insure_data_form
                        }).then(sum=>{
                            if (sum.data.code === 1){
                                this.mobileValue = this.insure_data_form.policy_holder_mobile;
                                this.check_mobile_dialog = true;
                            }
                        })
                    } else {
                        this.$message.error('请完善投保信息');
                        return false;
                    }
                });
            },
            get_card_all(){//证件号重复
                let data = this.insureds;
                for (let i = 0; i < data.length; i++) {
                    for (let j = i + 1; j < data.length; j++) {
                        if (data[i].cn === data[j].cn) {
                            this.$message.error('证件号重复');
                            return;
                        }
                    }
                }
            },
            getidcard(row){//获取性别，年龄
                if(!(/(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/.test(row.cn))){
                    this.$message.error('身份证号码格式错误');
                    return;
                }
                let data = this.insureds;
                for (let i = 0; i < data.length; i++) {
                    for (let j = i + 1; j < data.length; j++) {
                        if (data[i].cn === data[j].cn) {
                            this.$message.error('身份证号重复');
                            return;
                        }
                    }
                }
                row.b=row.cn.substring(6, 10) + "/" + row.cn.substring(10, 12) + "/" + row.cn.substring(12, 14);
                if (parseInt(row.cn.substr(16, 1)) % 2 == 1) {
                    row.s = '男'
                } else {
                    row.s = '女';
                }
            },
            addtable_data(){//添加一条参保人
                if (this.insureds.length >=200){
                    this.$message.error('被保人数量不能超过200人');
                    return;
                }
                let date = new Date().getTime();
                this.insureds.unshift({id:'id_'+ date, n:'',ct:'',cn:'',s:'',b:'',m:'',})
            },
            verifycode(){//验证手机号是否正确
                this.$ajax.post(app.API_URL + '/login/verifyCode',{
                    mobile:this.mobileValue,
                    code:this.mobileCode,
                }).then(res=>{
                    if (res.data.code === 1){
                        this.$ajax.post(app.API_URL+'/order/submit',{
                            insureds: JSON.stringify(this.insureds),
	                        ...this.insure_data_form
                        }).then(sum=>{
                            if (sum.data.code === 1){
                                this.$router.push({path:'/puer/pay_select',query:{id:sum.data.data.order_id}})
                            }
                        })
                    }
                })
            },
            getAuthCode() {//获取验证码
                let mobile = this.mobileValue;
                this.erro_tip = '';
                if (mobile === '') {
                    this.erro_tip = '手机号码不能为空';
                    return;
                }
                if (!(/^1[3456789]\d{9}$/.test(mobile))) {
                    this.erro_tip = '手机号码格式错误';
                    return;
                }
                this.$ajax.post(app.API_URL + '/user/sendSMSByMobile', {
                    mobile: mobile
                }).then((res) => {
                    if (res.data.code === 1) {
                        this.sendAuthCode = false;
                        this.auth_time = 60;
                        this.$message.success('发送成功');
                        let auth_timetimer = setInterval(() => {
                            this.auth_time--;
                            if (this.auth_time <= 0) {
                                this.sendAuthCode = true;
                                clearInterval(auth_timetimer);
                            }
                        }, 1000);
                    }
                });
            },
            excel_change(file, fileList){//上传excel
                this.importfxx(file.raw);
            },
            importfxx(obj) {//解析excel数据
                this.file = obj;
                let rABS = false; //是否将文件读取为二进制字符串
                let f = this.file;
                let reader = new FileReader();
                FileReader.prototype.readAsBinaryString = (f)=> {
                    let binary = "";
                    let rABS = false; //是否将文件读取为二进制字符串
                    let pt = this;
                    let wb; //读取完成的数据
                    let outdata;
                    let reader = new FileReader();
                    reader.onload = (e)=> {
                        let bytes = new Uint8Array(reader.result);
                        let length = bytes.byteLength;
                        for(let i = 0; i < length; i++) {
                            binary += String.fromCharCode(bytes[i]);
                        }
                        let XLSX = require('xlsx');
                        if(rABS) {
                            wb = XLSX.read(btoa(fixdata(binary)), { //手动转化
                                type: 'base64'
                            });
                        } else {
                            wb = XLSX.read(binary, {
                                type: 'binary'
                            });
                        }
                        outdata = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]]);//outdata就是你想要的东西
	                    for (let i=12;i<outdata.length;i++){
	                        if (outdata[i].insuredEname||outdata[i].insuredCardNo){
	                            if (this.insureds.length >=200){
	                                this.$message.error('被保人数量不能超过200人');
	                                return;
	                            }
                                for (let j = i + 1; j < outdata.length; j++) {
                                    if (outdata[i].insuredCardNo === outdata[j].insuredCardNo) {
                                        this.$message.error('序号:'+outdata[i].index+',身份证号:'+outdata[i].insuredCardNo+'重复请删除后重新导入');
                                        return;
                                    }
                                }
                                for (let k = 0; k < this.insureds.length; k++) {
                                    if (outdata[i].insuredCardNo === this.insureds[k].cn) {
                                        this.$message.error('身份证号:'+outdata[i].insuredCardNo+'重复');
                                        return;
                                    }
                                }
                            }
	                    }
	                    for (let i=12;i<outdata.length;i++){
	                        if (outdata[i].insuredEname||outdata[i].insuredCardNo){
                                let date = new Date().getTime();
                                this.insureds.push({
                                    id:'ex_'+outdata[i].index+date,
                                    n:outdata[i].insuredEname,
                                    ct:outdata[i].insuredCardType,
                                    cn:outdata[i].insuredCardNo,
                                    b:outdata[i].insuredBirthday,
                                    s:outdata[i].__EMPTY,
                                    m:outdata[i].__EMPTY_1,
                                })
                            }
	                    }
	                    console.log(this.insureds)
                    };
                    reader.readAsArrayBuffer(f);
                };

                if(rABS) {
                    reader.readAsArrayBuffer(f);
                } else {
                    reader.readAsBinaryString(f);
                }
            },
            looke_file(url){//打开挂网文件
                window.open(app.OSS_URL + url)
            },
            handleCurrentChange(val) {
                this.currentPage = val
            },
            getRowKeys(row){//选择标识
                return row.id;
            },
            handleSelectionChange(val){//多选数据
                this.table_row_select = val
            },
            del_table(id){//删除单个
                this.$confirm('您确定要删除此条参保人数据么?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    for (let i=0;i<this.insureds.length;i++){
                        if (this.insureds[i].id === id){
                            this.insureds.splice(i,1)
                        }
                    }
                    this.$message.success('删除成功');
                }).catch(() => {
                    this.$message.info('已取消删除');
                });
            },
            delall_table(){//批量删除
	            let data = this.table_row_select;
	            let data_all = this.insureds;
	            if (data.length === 0){
	                this.$message.error('未选择参保人');
	                return;
	            }
                this.$confirm('您确定要删除'+data.length+'条参保人数据么?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    let thable = data_all.filter(item => !data.some(ele=>ele.id===item.id));
                    this.insureds = thable;
                    this.$message.success('删除成功');
                    this.$refs.insureds.clearSelection();
                }).catch(() => {
                    this.$message.info('已取消删除');
                });
            },
            downloadFun(val){//下载模板
                let date =new Date().getTime();
                window.open(app.OSS_URL+val+'?'+date);
            },
            tableRowClassName ({ row, rowIndex }) {
                row.index = rowIndex;
                if ((rowIndex + 1) % 2 === 0) {
                    return 'double';
                } else {
                    return '';
                }
            },
		    getRowClass ({ rowIndex }) {
                if (rowIndex == 0) {
                    return 'background:#F6F6F8'
                } else {
                    return ''
                }
            },
	    }
    }
</script>
<style>
	.double {
		background: #F8F8F8 !important;
	}
	.avatar-uploader .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}
	.avatar-uploader .el-upload:hover {
		border-color: #A72C1C;
	}
	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 120px;
		height: 120px;
		line-height: 120px;
		text-align: center;
	}
	.avatar {
		width: 120px;
		height: 120px;
		display: block;
	}
</style>
<style lang="less" scoped>
	.insure_div_img{
		width: 120px;
		height: 120px;
		position: relative;
		.postion_icon{
			position: absolute;
			font-size: 18px;
			color: #A72C1C;
			cursor: pointer;
			top: -5px;
			right: -10px;
		}
	}
	.insure_page_class{
		padding: 30px 100px;
		min-width: 1200px;
		.insure_body{
			background: #FFFFFF;
			padding: 20px;
			box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
			border-radius: 4px;
			.title_top_text{
				color: #333;
				font-weight: bold;
				font-size: 18px;
				border-left: 4px solid #A72C1C;
				padding-left: 24px;
			}
			.sumber_input_form{
				padding: 30px;
			}
			.programme_form{
				padding: 30px;
				display: flex;
				.programme_class{
					cursor: pointer;
					width: 207px;
					height: 44px;
					background: #FFFFFF;
					border-radius: 3px;
					border: 1px solid #666666;
					font-size: 16px;
					text-align: center;
					line-height: 44px;
					color: #666666;
					margin-right: 20px;
				}
				.programme_class_click{
					background: #F9EDEB;
					border: 1px solid #A72C1C;
					color: #82291D;
					position: relative;
					.img_icon{
						position: absolute;
						width: 18px;
						height: 17px;
						right: 0;
						top: -1px;
					}
				}
			}
			.sumber_table_div{
				padding: 10px 30px;
				.button_table{
					display: flex;
					justify-content: flex-start;
					margin-bottom: 20px;
				}
				.bottom_color_tips{
					font-size: 14px;
					color: #A72C1C;
					margin-top: 10px;
				}
				.pagination_class{
					display: flex;
					justify-content: flex-end;
					margin-top: 10px;
				}
			}
			.sumber_button{
				padding: 30px;
				display: flex;
				justify-content: flex-end;
				flex-wrap:wrap;
				align-items:center;
				color: #999999;
				font-size: 16px;
				.num_people{
					color: #A72C1C;
					font-size: 20px;
					font-weight: bold;
					padding: 0 5px;
				}
				.many_text{
					color: #A72C1C;
					font-size: 30px;
					font-weight: bold;
					padding-left: 10px;
				}
				.company{
					color: #A72C1C;
					font-size: 16px;
					font-weight: bold;
					padding: 0 5px;
					line-height: 50px;
				}
				.sumber_button_from{
					margin-left: 10px;
					width: 150px;
				}
			}
			.file_div_list{
				padding: 30px;
				display: flex;
				justify-content: flex-start;
				.list_onte{
					border-right: 1px solid #333;
					color: #A72C1C;
					font-size: 14px;
					padding: 0 10px;
					cursor: pointer;
					span{
						border-bottom: 1px solid #A72C1C;
					}
					&:last-child{
						border-right: none;
					}
				}
			}
			.guarantee_content_class{
				padding: 30px;
				.table_div{
					border: 1px solid #A72C1C;
					border-radius: 11px;
					overflow: hidden;
					.bottom_tab{
						display: flex;
						justify-content: space-between;
						border-bottom: 1px solid #A72C1C;
						&:last-child{
							border-bottom: none;
						}
						.div_table{
							width: 100%;
							background: #F9EDEB;
							text-align: center;
							height: 65px;
							line-height: 65px;
							color: #333;
							font-size: 15px;
							font-weight: bold;
							border-right: 1px solid #A72C1C;
							&:last-child{
								border-right: none;
							}
						}
						.one_width{
							width: 35%;
						}
						.right_class{
							font-weight: 400;
							background: #fff;
						}
						.towlin_class{
							line-height: 20px;
							padding-top: 10px;
						}
						.font_clor{
							color: #B51A0B;
						}
						.font_clor_bold{
							color: #B51A0B;
							font-weight: bold;
						}
						.table_top_class{
							background: #fff;
						}
						.many_bottom{
							color: #A72C1C;
							font-size: 13px;
							font-weight: bold;
							text-align: center;
							line-height: 50px;
							width: 50%;
						}
					}
				}
			}
		}
	}
	.check_mobile_div{
		.top_title_dialog{
			display: flex;
			justify-content: space-between;
			padding-top: 20px;
			.title_font{
				font-weight: bold;
				font-size: 18px;
				border-left: 4px solid #A72C1C;
				padding-left: 20px;
			}
			.close_icon{
				margin-right: 30px;
				width: 17px;
				height: 17px;
				cursor: pointer;
			}
		}
		.title_tip_class{
			color: #999999;
			font-size: 12px;
			padding-left: 25px;
		}
		.input_sumber_div{
			padding: 25px;
			.input_list_div {
				margin-top: 10px;
				.ingput_list_class {
					border-radius: 4px;
					margin-top: 30px;
					border: 1px solid #e2e2e2;
					background: #f6f6f8;
					padding: 10px 15px;
					width: 350px;
					display: flex;
					justify-content: flex-start;
					.icon_tel {
						width: 15px;
						margin-top: 6px;
						height: 20px;
						display: block;
						margin-right: 10px;
					}
					.input_class {
						border: none;
						width: 350px;
						height: 30px;
						background: none;
						color: #333333;
						font-size: 15px;
						line-height: 32px;
					}
					.input_class::-webkit-input-placeholder {
						color: #bababa !important;
					}
					.input_class::-moz-placeholder {
						/* Mozilla Firefox 19+ */
						color: #bababa !important;
					}
					.input_class:-moz-placeholder {
						/* Mozilla Firefox 4 to 18 */
						color: #bababa !important;
					}
					.input_class:-ms-input-placeholder {
						/* Internet Explorer 10-11 */
						color: #bababa !important;
					}
					.input_class:focus {
						outline: none;
						border: none;
						box-shadow: none;
					}
				}
				.erro_tip {
					color: #a72c1c;
					font-weight: 500;
					font-size: 14px;
					margin-top: 10px;
					height: 30px;
				}
				.code_input_div {
					border-radius: 4px;
					margin-top: 30px;
					border: 1px solid #e2e2e2;
					background: #f6f6f8;
					padding: 10px 15px;
					width: 350px;
					display: flex;
					justify-content: space-between;
					.code_button {
						width: 120px;
						color: #fff;
						font-size: 14px;
						height: 30px;
						background: #a72c1c;
						border-radius: 4px;
						border: none;
						cursor: pointer;
					}
					.input_class {
						border: none;
						width: 150px;
						background: none;
						color: #333333;
						font-size: 15px;
					}
					.input_class::-webkit-input-placeholder {
						color: #bababa !important;
					}
					.input_class::-moz-placeholder {
						/* Mozilla Firefox 19+ */
						color: #bababa !important;
					}
					.input_class:-moz-placeholder {
						/* Mozilla Firefox 4 to 18 */
						color: #bababa !important;
					}
					.input_class:-ms-input-placeholder {
						/* Internet Explorer 10-11 */
						color: #bababa !important;
					}
					.input_class:focus {
						outline: none;
						border: none;
						box-shadow: none;
					}
				}
				.sumber_button_from{
					margin-top: 20px;
					width: 100%;
					height: 40px;
				}
			}
		}
	}
</style>